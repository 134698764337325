'use client'
import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import { Link } from '@/navigation'
import getPostImage from '@/utils/post/getPostImage'
import { Skeleton } from '@mui/material'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'
import CustomNavigation from '../swiper/CustomNavigation'

export default function CCP_HomeAboutNetworkHospital({
  campusHospitals,
  isLoading,
}) {
  const __t = useTranslations()
  const [disabledPrev, setDisabledPrev] = useState(true)
  const [disabledNext, setDisabledNext] = useState(false)
  function onSlideChange(event) {
    setDisabledPrev(event.isBeginning)
    setDisabledNext(event.isEnd)
  }

  return (
    <div className="md:tw-col-span-4">
      <div
        className={`tw-shadow-main-blue-fade tw-h-24 tw-rounded-xl tw-relative tw-duration-200 ${
          isLoading ? 'tw-bg-bgh-skeleton' : 'tw-bg-white'
        }`}
      >
        {/* Image slide */}
        <CCP_MySwiper
          slidesPerView={1}
          spaceBetween={16}
          loop={false}
          swiperClass=""
          swiperSlideClass="!tw-w-full"
          autoplay={{ delay: 3000 }}
          onSlideChange={(event) => onSlideChange(event)}
          slides={campusHospitals?.map((campus, i) => (
            <Link
              aria-label={`${__t('network-hospitals')}`}
              prefetch={false}
              href={campus.link || '#'}
              key={`home-network-hospital-${i}`}
              className="center-child tw-w-full tw-h-full"
            >
              <Image
                width={190}
                height={32}
                alt="Hospital Image"
                src={getPostImage(campus)}
                className="tw-object-contain hover:tw-scale-105 tw-duration-200 !tw-max-h-12"
              />
            </Link>
          ))}
        >
          {/* Navigation */}
          {isLoading ? null : (
            <CustomNavigation
              disabledPrev={disabledPrev}
              disabledNext={disabledNext}
            />
          )}
        </CCP_MySwiper>
      </div>
      {isLoading ? (
        <Skeleton
          variant="text"
          width="60%"
          className="tw-text-2xl !tw-mt-2 tw-mx-auto"
        />
      ) : (
        <span className="tw-inline-block tw-w-full tw-text-primary tw-text-center tw-capitalize !tw-font-semibold tw-mt-2 max-md:tw-text-sm">
          {__t('network-hospitals')}
        </span>
      )}
    </div>
  )
}

'use client'
import useSWR from 'swr'
import { NEXT_INSTANCE } from '@/lib/api'
import { useEffect } from 'react'
import useAuth from '@/store/auth'
import { Skeleton } from '@mui/material'
import useAppointment from '@/store/appointment'
import qs from 'qs'
import { useLocale } from 'next-intl'
import ErrorState from '../element/ErrorState'
import { usePathname } from '@/navigation'

const fetcher = (resource) => NEXT_INSTANCE(resource).then((d) => d.data)

export default function CCP_MyAppointmentProvider({
  children,
  height = 220,
  width = '100%',
  showErrorState = true,
  showLoadingState = true,
}) {
  const locale = useLocale()
  const { realtimeForm, setServices, isHNLoading } = useAppointment()
  const { setMyAppointments, isUserMeLoading, userMe } = useAuth()
  const HN = realtimeForm?.HN
  const pathname = usePathname()

  //Get my appointment
  const {
    data: myAppointmentData,
    error,
    isLoading: isAppointmentLoading,
  } = useSWR(HN ? `/next-api/epms/get-appointment/?HN=${HN}` : null, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  })

  let _serviceQuery = {
    post_type: 'service',
    locale,
    posts_per_page: 100,
  }
  let isAppointmentOrMyAccount =
    pathname.includes('appointment') || pathname.includes('my-account')
  console.log(pathname)
  let query = isAppointmentOrMyAccount
    ? `v2/query?${qs.stringify(_serviceQuery)}`
    : null
  const { data: servicePosts, isLoading: isServiceLoading } = useSWR(query, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  })

  useEffect(() => {
    if (myAppointmentData && setMyAppointments) {
      setMyAppointments(myAppointmentData)
    }
  }, [myAppointmentData, setMyAppointments, isHNLoading])

  useEffect(() => {
    if (servicePosts) {
      setServices(servicePosts.posts)
    }
  }, [servicePosts, setServices, isHNLoading])

  if (
    ((isHNLoading && !HN) ||
      (isServiceLoading && !servicePosts) ||
      (isUserMeLoading && !userMe) ||
      (isAppointmentLoading && !myAppointmentData)) &&
    showLoadingState
  )
    return (
      <Skeleton
        variant="rectangular"
        height={height}
        width={width}
        className="tw-rounded-xl tw-mt-2"
      />
    )

  if (error || error?.Code === 'ERROR' || myAppointmentData?.Code === 'ERROR') {
    return (
      <>
        Found Error: <pre>{JSON.stringify(error, null, 2)}</pre>
      </>
    )
  }

  if (!HN && showErrorState) {
    return null
  }
  return <>{children}</>
}

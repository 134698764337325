import { Link } from '@/navigation'
import useAuth from '@/store/auth'
import { useTranslations } from 'next-intl'

export default function CCP_HomeBannerUpcomingAppointments() {
  const __t = useTranslations()
  const { userMe, isUserMeLoading, myAppointments } = useAuth()

  if (!myAppointments || !userMe) {
    return
  }
  const schedule = myAppointments?.data?.Result?.Schedules?.Schedule

  const scheduleLength = schedule?.length

  if (isUserMeLoading || !userMe || !scheduleLength) {
    return null
  }

  return (
    <Link
      prefetch={false}
      href="/my-account/appointment"
      className="tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-text-bgh-fpd-blue tw-bg-bgh-blue-alpha tw-py-1 tw-px-1.5 tw-rounded tw-mt-2 tw-w-fit"
    >
      <i className="fal fa-calendar-alt tw-text-xs !tw-font-light tw-relative tw-mb-[1px]"></i>
      <span className="tw-text-xs tw-font-normal">
        {__t('upcoming-appointments', {
          appointments: scheduleLength,
        })}
      </span>
    </Link>
  )
}

// <Skeleton
//   variant="rounded"
//   width={150}
//   height={24}
//   classes={{ root: '!tw-rounded-full tw-mt-2' }}
// />

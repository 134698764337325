import { Link } from '@/navigation'
import { Skeleton } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { useTranslations } from 'next-intl'

export default function SCP_IconButtonWithText({
  link,
  label,
  icon,
  isLoading,
}) {
  const __t = useTranslations()
  return (
    <Link
      prefetch={false}
      href={link ? link : '/'}
      aria-label={`${__t('go-to')} ${label}`}
    >
      {isLoading ? (
        <Skeleton
          variant="rounded"
          width={60}
          height={32}
          className="tw-mr-2 tw-mb-2"
        />
      ) : (
        <ListItemButton component="div" className="menu-chip tw-group">
          <i
            className={`fas fa-${icon} tw-text-primary group-hover:tw-text-bgh-blue tw-duration-200`}
          />
          <span className="!tw-font-semibold tw-text-primary tw-capitalize group-hover:tw-text-bgh-blue tw-duration-200">
            {label}
          </span>
        </ListItemButton>
      )}
    </Link>
  )
}

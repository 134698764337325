'use client'

import AnimateArrow from '../element/AnimateArrow'
import { useSwiper } from 'swiper/react'

const CustomNavigation = ({ disabledPrev, disabledNext }) => {
  const swiper = useSwiper()
  return (
    <>
      <div
        onClick={() => swiper.slidePrev()}
        className={`tw-absolute tw-z-10 tw-w-8 tw-h-8  tw-top-1/2 -tw-translate-y-1/2 ltr:tw-left-4 rtl:tw-left-8 ${
          disabledPrev
            ? 'tw-cursor-not-allowed tw-opacity-50'
            : 'tw-cursor-pointer'
        }`}
      >
        <AnimateArrow addedClass="tw-rotate-180" />
      </div>
      <div
        onClick={() => swiper.slideNext()}
        className={`tw-absolute tw-z-10 tw-w-8 tw-h-8 tw-top-1/2 -tw-translate-y-1/2 ltr:tw-right-4 rtl:tw-right-8 ${
          disabledNext
            ? 'tw-cursor-not-allowed tw-opacity-50'
            : 'tw-cursor-pointer'
        }`}
      >
        <AnimateArrow />
      </div>
    </>
  )
}

export default CustomNavigation

import { Skeleton } from '@mui/material'
import CCP_TransitionImageChild from '../element/TransitionImageChild'

export default function SCP_IconButton({ isLoading, icon, iconSrc, title }) {
  return (
    <div
      className={`tw-cursor-pointer tw-w-full tw-rounded-lg tw-flex  tw-flex-col  tw-space-y-2 tw-items-center tw-group hover:tw-scale-[102%] tw-duration-200 ${
        isLoading ? 'tw-pointer-events-none' : ''
      }`}
    >
      {isLoading ? (
        <Skeleton variant="rounded" width={64} height={64} />
      ) : (
        <div className="2xl:tw-size-16 md:tw-size-14 tw-size-12 tw-p-3  tw-rounded-md tw-relative tw-flex-none">
          {icon ? (
            <i className={`${icon} tw-text-2xl tw-text-primary`} />
          ) : null}
          {iconSrc ? (
            <CCP_TransitionImageChild
              alt="Icon Image"
              src={iconSrc}
              addedClass="!tw-object-contain !tw-p-2"
            />
          ) : null}
        </div>
      )}
      {isLoading ? (
        <>
          <Skeleton variant="text" width="60%" />
        </>
      ) : (
        <>
          <span className="tw-line-clamp-1 group-hover:tw-text-bgh-blue tw-text-primary sm:tw-text-base tw-text-sm !tw-font-semibold tw-text-center tw-duration-200">
            {title}
          </span>
        </>
      )}
    </div>
  )
}

export default function SCP_AnimateArrow({ addedClass = '' }) {
  return (
    <div className={`tw-duration-200 ${addedClass}`}>
      <i className="rtl:-tw-ml-2 far fa-chevron-right tw-text-bgh-blue-gloomy tw-animate-to-right" />
      <i
        style={{ animationDelay: '800ms' }}
        className="ltr:-tw-ml-3 far fa-chevron-right tw-text-bgh-blue-gloomy tw-animate-to-right"
      />
    </div>
  )
}
